
import { defineComponent, ref } from "vue";
import fetchApi from "@/api";
import { eventBus, getUrlParams } from "@/utils";
import { ListItem } from "./components";
import { BusinessInfoItem } from "./../type";

export default defineComponent({
  name: "FormInfoDetail",
  components: { ListItem },
  setup() {
    const search = getUrlParams();
    const list = ref<any>([]);
    const loading = ref(false);
    const finished = ref(false);
    const refreshing = ref(false);
    const pageIndex = ref(1);
    const decodeOfferData = ref<{ [_: string]: string }>({});

    eventBus.on("decodeOffer", (processInstanceId) => {
      fetchApi
        .getDecodeOffer()
        .data({
          processInstanceId,
        })
        .send("POST")
        .then((res) => {
          if (res && res.data) {
            const data = JSON.parse(res.data);
            decodeOfferData.value = data;
          }
        });
    });

    const onLoad = () => {
      if (refreshing.value) {
        list.value = [];
        refreshing.value = false;
        pageIndex.value = 1;
      }
      loading.value = true;
      return fetchApi
        .getSubFromDetail()
        .data({
          pageNo: pageIndex.value,
          pageSize: 10,
          pid: search.get("processInstanceId"),
          formCode: search.get("formCode"),
        })
        .send("POST")
        .then((res) => {
          if (res.data) {
            const data = JSON.parse(res.data);
            console.log(data);
            const { fieldChineseNameMap, records, formName } = data;
            document.title = "业务明细-" + formName;
            const resList: BusinessInfoItem[] = records.result.map(
              (item: any) => {
                return Object.keys(fieldChineseNameMap).reduce(
                  (res: any, key: string) => {
                    if (item[key]) {
                      return [
                        ...res,
                        {
                          key,
                          label: fieldChineseNameMap[key],
                          value: item[key],
                        },
                      ];
                    }
                    return res;
                  },
                  []
                );
              }
            );
            list.value = [...list.value, ...resList];
            if (
              records.totalCount <= list.value.length ||
              records.result.length === 0
            ) {
              finished.value = true;
            }
          }
          pageIndex.value++;
          loading.value = false;
        })
        .catch(() => {
          loading.value = false;
          finished.value = true;
        });
    };
    const onRefresh = () => {
      if (loading.value) {
        refreshing.value = false;
        return;
      }
      // 清空列表数据
      finished.value = false;
      refreshing.value = true;
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      loading.value = true;
      onLoad();
    };

    return {
      list,
      loading,
      finished,
      refreshing,
      onLoad,
      decodeOfferData,
      onRefresh,
    };
  },
});
