
import { defineComponent, PropType, toRefs, watchEffect, ref } from "vue";
import useTaskStatus from "@/hooks/useTaskStatus";
import { DetailProps } from "./../../type";

export default defineComponent({
  name: "StatusSignet",
  components: {},
  props: {
    data: {
      type: Object as PropType<DetailProps | undefined>,
      default: () => null,
    },
    type: {
      type: String,
      default: "default",
    },
  },
  setup(props) {
    const { data, type } = toRefs(props);
    const url = ref("");
    watchEffect(() => {
      if (data.value) {
        const { imgUrl } = useTaskStatus({
          type: type.value,
          data: data.value,
        });
        url.value = imgUrl;
      }
    });
    return { url };
  },
});
