import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "detail-list-item-label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ListItem = _resolveComponent("ListItem")!
  const _component_van_cell = _resolveComponent("van-cell")!
  const _component_van_list = _resolveComponent("van-list")!
  const _component_van_pull_refresh = _resolveComponent("van-pull-refresh")!

  return (_openBlock(), _createBlock(_component_van_pull_refresh, {
    class: "time-list",
    modelValue: _ctx.refreshing,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.refreshing) = $event)),
    onRefresh: _ctx.onRefresh
  }, {
    default: _withCtx(() => [
      _createVNode(_component_van_list, {
        loading: _ctx.loading,
        "onUpdate:loading": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.loading) = $event)),
        finished: _ctx.finished,
        offset: "100",
        "finished-text": _ctx.list.length === 0 ? '' : '没有更多了',
        onLoad: _ctx.onLoad,
        "loading-text": "加载中..."
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, (data, index) => {
            return (_openBlock(), _createBlock(_component_van_cell, { key: index }, {
              value: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(data, (item, index) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: index,
                    class: "detail-list-item"
                  }, [
                    _createElementVNode("div", _hoisted_1, _toDisplayString(item.label), 1),
                    _createVNode(_component_ListItem, {
                      data: item.value,
                      decodeData: _ctx.decodeOfferData[item.key]
                    }, null, 8, ["data", "decodeData"])
                  ]))
                }), 128))
              ]),
              _: 2
            }, 1024))
          }), 128))
        ]),
        _: 1
      }, 8, ["loading", "finished", "finished-text", "onLoad"])
    ]),
    _: 1
  }, 8, ["modelValue", "onRefresh"]))
}