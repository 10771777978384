
import { defineComponent, PropType, computed, toRefs } from "vue";
import { getFileType } from "@/utils";
import fetchApi from "@/api";
import { ItemValue } from "./../../type";
import { Dialog } from "vant";

export default defineComponent({
  name: "ListItem",
  emits: ["preview", "showSpin"],
  props: {
    data: {
      type: [String, Array] as PropType<ItemValue>,
    },
    decodeData: {
      type: String,
    },
    contentStyle: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  setup(props, context) {
    const { data, decodeData } = toRefs(props);
    const dataItem = computed(() => {
      if (decodeData.value) {
        return decodeData.value;
      }
      let _data = data.value;
      if (typeof data.value === "string" && data.value[0] === "[") {
        try {
          _data = eval(data.value);
        } catch (e) {
          //
        }
      }
      return _data;
    });
    const doDownload = (file: {
      fileName: string;
      filePath: string;
      fileId: string;
      fileUrl: string;
    }) => {
      const filePath = file.filePath || file.fileUrl;
      if (!filePath) {
        Dialog.alert({ message: "附件链接未找到" });
        return;
      }
      const fileType = getFileType(filePath);
      // if (fileType.type === "error") {
      //   Dialog.alert({ message: "此链接不支持预览，请在PC端查看" });
      //   return;
      // }
      const previewConfig = {
        type: "",
        url: "",
      };
      previewConfig.type = fileType.type;
      // 链接类型
      if (fileType.type === "link") {
        window.open(fileType.url);
        return;
      }
      // 图片类型
      if (fileType.type === "img") {
        previewConfig.url = fileType.url;
        context.emit("preview", previewConfig);
        return;
      }
      // office类型
      if (fileType.type === "office") {
        context.emit("showSpin", true);
        fetchApi
          .previewOffice()
          .data({
            downloadUrl: filePath,
            fileId: file.fileId,
          })
          .send("POST")
          .then((res) => {
            context.emit("showSpin", false);
            if (res.data) {
              previewConfig.url = res.data;
              context.emit("preview", previewConfig);
            }
          })
          .catch(() => {
            context.emit("showSpin", false);
          });
        return;
      }
      // 其余类型--使用京ME-SDK
      window.jme.file.openDocument({
        downloadUrl: filePath,
        name: file.fileName,
        size: 29708,
        type: fileType.type,
      });
    };
    const openUrl = (url: any) => {
      try {
        if (!url) {
          return;
        }
        if (url.startsWith?.("jme")) {
          window.jme?.browser?.callDeepLink({ url });
        } else {
          window.jme?.browser?.openUrl({ url });
        }
      } catch (e) {
        console.error(e);
      }
    };
    return { openUrl, dataItem, doDownload };
  },
});
