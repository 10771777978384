import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "detail-list-item-value"
}
const _hoisted_2 = { style: {"display":"flex","flex-direction":"column"} }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = {
  key: 1,
  class: "detail-list-item-value"
}
const _hoisted_5 = { class: "item-link" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_van_icon = _resolveComponent("van-icon")!
  const _component_EllipsisText = _resolveComponent("EllipsisText")!

  return (Array.isArray(_ctx.dataItem))
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dataItem, (linkItem, i) => {
            return (_openBlock(), _createElementBlock("span", {
              key: i,
              style: _normalizeStyle({
          ..._ctx.contentStyle,
          marginBottom: 8,
        })
            }, [
              _createVNode(_component_van_icon, {
                name: "link-o",
                color: "var(--primary-color)",
                style: {"margin-right":"8px"}
              }),
              _createElementVNode("a", {
                href: "javascript:void(0)",
                style: {"color":"var(--primary-color)"},
                onClick: _withModifiers(() => _ctx.doDownload(linkItem), ["stop"])
              }, _toDisplayString(linkItem.fileName), 9, _hoisted_3)
            ], 4))
          }), 128))
        ])
      ]))
    : (/^(http|ftp|https|jdme):\/\//.test(_ctx.dataItem || ''))
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createElementVNode("span", _hoisted_5, [
            _createVNode(_component_van_icon, {
              name: "link-o",
              color: "var(--primary-color)",
              style: {"margin-right":"8px"}
            }),
            _createElementVNode("a", {
              onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.openUrl(_ctx.dataItem)), ["stop"])),
              style: {"color":"var(--primary-color)"}
            }, _toDisplayString(_ctx.dataItem), 1)
          ])
        ]))
      : (_openBlock(), _createBlock(_component_EllipsisText, {
          key: 2,
          class: "detail-list-item-value",
          title: _ctx.dataItem
        }, null, 8, ["title"]))
}