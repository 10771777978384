import { DetailProps } from "@/views/type";

interface TaskStatusProps {
  type: string;
  data?: DetailProps;
}

// 获取当前任务的状态
export default function getTaskStatus(option: TaskStatusProps) {
  const { type, data: detail } = option;

  if (type === "waitSign") {
    return {
      title: "等待加签",
      value: "waitSign",
      imgUrl: "https://storage.jd.com/cho-fe/imgs/applyDetail_waitSign.png",
    };
  } else if (type === "addSign") {
    return {
      title: "加签",
      value: "sign",
      imgUrl: "https://storage.jd.com/cho-fe/imgs/applyDetail_Sign.png",
    };
  } else {
    if (detail?.status === "0") {
      return {
        title: "草稿",
        value: "draft",
        imgUrl:
          "https://joysky-prod.s3.cn-north-1.jdcloud-oss.com/images/draft_3.png",
      };
    }
    if (detail?.status === "2") {
      return {
        title: "被拒绝",
        value: "refuse",
        // imgUrl: "https://storage.jd.com/cho-fe/imgs/applyDetail_doingBig.png",
        imgUrl:
          "https://joysky-prod.s3.cn-north-1.jdcloud-oss.com/images/approve_3.png",
      };
    }
    if (detail?.status === "3") {
      return {
        title: "被驳回",
        value: "reject",
        // imgUrl: "https://storage.jd.com/cho-fe/imgs/applyDetail_refuseBig.png",
        imgUrl:
          "https://joysky-prod.s3.cn-north-1.jdcloud-oss.com/images/reject_3.png",
      };
    }
    if (detail?.status === "4") {
      return {
        title: "被取消",
        value: "cancel",
        // imgUrl: "https://storage.jd.com/cho-fe/imgs/applyDetail_finishedBig.png",
        imgUrl:
          "https://joysky-prod.s3.cn-north-1.jdcloud-oss.com/images/cancelled_3.png",
      };
    }
    if (detail?.status === "5") {
      return {
        title: "已完成",
        value: "finished",
        // imgUrl: "https://storage.jd.com/cho-fe/imgs/applyDetail_finishedBig.png",
        imgUrl:
          "https://joysky-prod.s3.cn-north-1.jdcloud-oss.com/images/end_3.png",
      };
    }
    // if (detail?.status === "1") {
    return {
      title: "审批中",
      value: "doing",
      // imgUrl: "https://storage.jd.com/cho-fe/imgs/applyDetail_doingBig.png",
      imgUrl:
        "https://joysky-prod.s3.cn-north-1.jdcloud-oss.com/images/approve_3.png",
    };
    // }
  }
}
